import React from "react";
import "./Tag.css";

class Tags extends React.Component {
    render() {
        return (
            <div
                className="styling"
                color="red"
            >
                <h1
                >
                    {this.props.label}
                </h1>
            </div>
        );
    }
}

export default Tags;