import React from "react";
import './Header.css';
import Pages from './Pages.js';

class Header extends React.Component {
    render() {
        return (
            <body
                id="rotation-anim"
            >
                <div 
                    className="comp"
                    id="hyperlinks"
                >
                    <Pages name="Home"/>
                    <Pages name="Projects"/>
                    <Pages name="Piano"/>
                    <Pages name="Hobbies"/>
                </div>
            </body>
        );
    }
}

export default Header;