import Header from '../components/Header.js';
import React from "react";
import './Home.css';
import pic from './pics/CaliMe.jpg';
import Tag from "./Tag.js"
import FamilyImages from './FamilyImages.js';
import skye from "./pics/skye-dog.jpg";
import piano from "./pics/piano-prom.jpeg";
import fam from "./pics/FamChristmas.jpg";

class Home extends React.Component {
    render() {
        return (
            <div
                className="screen"
            >
                <div
                    id="rotate-anim"
                >
                    <Header 
                        className="header"
                    />
                </div>
                <div 
                    className="home-full"
                >   
                    <div
                        className="main-text"
                    >   
                        <div
                            className="who"
                        >
                            <Tag
                                label="Who am I?"
                            />
                        </div>
                        <div
                            className="about-me-text-div"
                        >
                            <h2
                                className="about-me-text"
                                id="about-me-text-2"
                            >
                                My name is Anderson Lawrence, and two years ago I attended Ithaca College for physics. 
                                I'm presently at Boston University, studying Computer Science and working towards a finance minor. 
                                When I'm at home in Belmont, Massachussets, I like to spend time with my friends, family, and border-collie Skye.
                            </h2>
                        </div>
                        <div
                            className="enjoy-tag"
                        >
                            <Tag
                                label="What do I enjoy?"
                            />
                        </div>
                        <h2
                            className="about-me-text"
                            id="about-me-text-2"
                        >
                            When I find myself with any free time, usually I enjoy listening to and creating music in
                            ProTools, finding a project, or playing the piano. I've also taken interest in learning programming
                            languages online, including Solidity and JavaScript.
                        </h2>
                        <div
                            className="images-text-align"
                        >
                        </div>
                        <div
                            className="images-tag"
                        >
                            <div
                                className="rot-1"
                            >
                                <FamilyImages
                                    name={skye}
                                    caption="Pic of Skye"
                                />
                            </div>
                            <div
                                className="fam-picture"
                            >
                            <FamilyImages
                                name={fam}
                                className="rot-2"
                                caption="X-mas 2017"
                            />
                            </div>
                            <div
                                className="rot-1"
                            >
                                <FamilyImages
                                    name={piano}
                                    caption="Piano"
                                />
                            </div>
                        </div>
                    </div>
                    <div 
                        className="divider"
                        id="full-display"
                    >
                        <img 
                            className="pic-of-me"
                            src={pic}
                            width="90%"
                            alt="Me in California"
                        />
                        <figcaption
                            id="rotat"
                        >
                            Me in California
                        </figcaption>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;