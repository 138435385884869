import React from "react";
import './Pages.css';
import { useState } from "react";
import { Link } from "react-router-dom";

class Pages extends React.Component {
    render() {

        const thisName = "" + this.props.name;
        let newName = thisName.toLowerCase();

        if (newName == "home") {
            newName = "";
        }

        return (
            <body
                id="pages"
            >
                <p
                    className="hyperlinks"
                    id={"" + this.props.name}
                >
                    <li
                        className="list-links"
                    >
                        <Link 
                            to={"/" + newName}
                            className="links"
                        >
                            {this.props.name}
                        </Link>
                    </li>
                </p>
            </body>
        );
    }
}

export default Pages;