import logo from './logo.svg';
import './App.css';
import Header from './components/Header.js';
import Home from './Pages/Home.js';
import Projects from './Pages/Projects.js';
import Testing from './components/Testing.js';
import Homey from './Pages/Homey.js';
import Hobbies from './Pages/Hobbies.js';

import { 
  BrowserRouter as Router, Routes, Route, Link, Switch
} from "react-router-dom";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route exact path="/" element={<Homey />} />
          <Route exact path="projects" element={<Projects />} />
          <Route exact path="piano" element={<Testing />} />
          <Route exact path="hobbies" element={<Hobbies />} />
        </Routes>
      </Router>  
    </div>
  );
}

export default App;
