import Header from '../components/Header.js';
import React from "react";
import './Home.css';
import './Projects.css';
import pic from './pics/CaliMe.jpg';
import Tag from "./Tag.js"
import gladlibs from "./pics/Gladlibs.png";
import FamilyImages from './FamilyImages.js';

class Projects extends React.Component {
    render() {

        return (
            <body
                id="projects"
            >
                <div
                    className="header-projects"
                >
                    <Header/>
                </div>
                <div
                    className="home added-padding"
                >
                    <div
                        className="gladlibs"
                    >
                        <FamilyImages
                            name={gladlibs}
                            caption="Gladlibs"
                        />
                    </div>
                    <div
                        className="align-gladlibs tag"
                    >
                        <div
                            className="gladlibs-tag"
                        >
                            <Tag    
                                label="Gladlibs"
                            />
                        </div>
                        <h4
                            className="gladlibs-text text"
                        >
                            Gladlibs adopts its fun from the well-known game Madlibs and incorporates an interactive animated video game that helps
                            to engage a large range of users who seek a laughing experience. The program takes a text, given by the user, 
                            and then randomizes "blanks" into the text, extrapolating the part of speech (POS) from the 
                            blank-converted word. The user is then prompted for words that correspond to these POS; the software then 
                            places these words into the blanks, leading to a funny result!
                        </h4>
                    </div>
                </div>
                <div
                    className="home research"
                >
                    <div
                        className="tag research-tag"
                    >
                        <Tag
                            label="Research"
                        />
                    </div>
                    <div>
                    <h4
                        className="text research-text"
                    >
                        Last year at Ithaca, I performed research using optical tweezers. Our purpose was to inspect colloidal spheres and their properties; 
                        some classified as suspended undergoing atypical dynamics, and others categorized by Brownian motion. My task in research was to use 
                        Python in order to retreive data attained by optical tweezers. This data would be used to categorize molecules and their properties: 
                        viscosity, reflective/refractive indicies, and interactions between other molecules and forces. I've also coded a game called "Gladlibs,"
                        which can be downloaded at the bottom of the "Gladlibs" page.
                    </h4>
                    </div>
                </div>
            </body>
        );
    }
}

export default Projects;

/*
<div
    className="meme"
>
    <div
        id="rotate-anim-projects"
    >
        <Header 
            className="header"
        />
    </div>
    <div 
        className="projects-full"
    >   
        <div
            className="main-text-projects"
        >
            <div
                className="gladlibs-tag-align"
            >
                <Tag
                    label="Gladlibs"
                    className="who"
                />
            </div>
            <div
                className="about-me-text-div"
            >
                <div
                    className="gladlibs-image-align"
                >   
                    <div
                        className="projects-image-gladlibs"
                    >
                        <img
                            src={gladlibs}
                            width="50%"
                            className="image-gladlibs"
                        />
                    </div>
                    <div
                        className="text-and-research-projects"
                    >
                        <div
                            className="gladlibs-text-only"
                        >
                            <h2
                                className="about-me-text"
                                id="about-me-text-3"
                            >
                                Gladlibs adopts its fun from the well-known game Madlibs and incorporates an interactive animated video game that helps
                                to engage a large range of users who seek a laughing experience. The program takes a text, given by the user, 
                                and then randomizes "blanks" into the text, extrapolating the part of speech (POS) from the 
                                blank-converted word. The user is then prompted for words that correspond to these POS'; the software then 
                                places these words into the blanks, leading to a funny result!
                            </h2>
                        </div>
                        <div
                            className="research-projects-tag"
                        >
                            <Tag    
                                label="Research"
                            />
                        </div>
                        <div
                            className="about-me-text-align"
                        >
                            <h2
                                className="about-me-text"
                                id="about-me-text-4"
                            >
                                Last year at Ithaca, I performed research using optical tweezers. Our purpose was to inspect colloidal spheres and their properties; 
                                some classified as suspended undergoing atypical dynamics, and others categorized by Brownian motion. My task in research was to use 
                                Python in order to retreive data attained by optical tweezers. This data would be used to categorize molecules and their properties: 
                                viscosity, reflective/refractive indicies, and interactions between other molecules and forces. I've also coded a game called "Gladlibs,"
                                which can be downloaded at the bottom of the "Gladlibs" page.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */