import React from "react";
import "./Hobbies.css";
import Header from "../components/Header.js";
import Run from "./pics/AndersRun.jpg";
import Shot from "./pics/AndersShot.jpg";
import Basketball from "./pics/BasketballMe.jpg";
import Rugby from "./pics/RugbyMe.jpg";
import FamilyImages from "./FamilyImages.js";
import Tags from "./Tag.js";

class Hobbies extends React.Component {
    render() {
        return (
            <body
                id="hobbies"
            >
                <div
                    className="header-hobbies"
                >    
                    <Header/>
                </div>
                <div
                    className="home hobbies-full"
                >
                    <div
                        className="wolfhounds hobbies-pic"
                    >
                        <FamilyImages
                            name={Rugby}
                            caption="Wolfhounds Rugby"
                        />
                    </div>
                    <div
                        className="tag rugby-text-div"
                    >
                        <div
                            className="rugby-tag"
                        >
                            <Tags
                                label="Past"
                            />
                        </div>
                        <h4
                            className="text rugby-text"
                        >   
                            Over the course of High School, I became very passionate about playing rugby. Prior to that, 
                            I had played both soccer and basketball, but I parted from basketball when I reached the 
                            ninth grade. In between injuries (acl and thumb), I continued playing rugby and soccer for
                            three out of my four years in high school. Below, I've attached some images of my former pursuits!
                        </h4>
                    </div>  
                </div>
                <div
                    className="home what-enjoy"
                >
                    <div
                        className="who-am-i what-do-div"
                    >
                        <Tags
                            label="What do I enjoy?"
                        />
                    </div>
                    <h4
                        className="text pad-right"
                    >
                        When I find myself with any free time, usually I enjoy listening to and creating music in
                        ProTools, finding a project, or playing the piano. I've also taken interest in learning programming
                        languages online, including Solidity and JavaScript.
                    </h4>
                </div>
                <div
                    className="home pics-bottom"
                >
                    <div
                        className="picture soccer-pic"
                    >
                        <FamilyImages
                            name={Shot}
                            caption="High School Soccer"
                        />
                    </div>
                    <div
                        className="picture shot-pic"
                    >
                        <FamilyImages
                            name={Basketball}
                            caption="On The Court"
                        />
                    </div>
                    <div
                        className="picture rugby-pic"
                    >
                        <FamilyImages
                            name={Run}
                            caption="High School Rugby"
                        />
                    </div>
                </div>
            </body>
        );
    }
}

export default Hobbies;