import React from "react";
import "./FamilyImages.css";
import skye from "./pics/skye-dog.jpg";
import piano from "./pics/piano-prom.jpeg";
import fam from "./pics/FamChristmas.jpg";
import "./Home.css";

class FamilyImages extends React.Component {

    render() {

        let thisName = this.props.name;

        return (
            <div
                className="family-images"
            >
                <img
                    className="pic-of-me"
                    src={thisName}
                    width="88%"
                />
                <figcaption
                    className="image-text-under">
                    {this.props.caption}
                </figcaption>
            </div> 
        );
    }
}

export default FamilyImages;