import "./Homey.css";
import React from "react";
import Header from "../components/Header.js";
import Tags from "./Tag.js";
import FamilyImages from "./FamilyImages.js";
import CaliMe from "./pics/CaliMe.jpg";
import SkyeDog from "./pics/skye-dog.jpg";
import PianoPic from "./pics/piano-prom.jpeg";
import FamilyPic from "./pics/FamChristmas.jpg";

class Homey extends React.Component {
    render() {
        function bringToTopFirst() {
            const attempts = document.getElementsByClassName("try-me-again");

            for (let i = 0; i < attempts.length; i++) {
                let thisNum = (attempts[i].offsetTop - window.pageYOffset)/10;
                attempts[i].style.transform = "translateY(" + thisNum + "vmin)";
                if (thisNum > 0 && i != 2) {
                    attempts[i].style.opacity = "" + 1/(thisNum/10);
                }
            }
        }

        window.onscroll = function() {
            if (!mobileDevice()) {
                bringToTopFirst();
            } 
            topAnim();
        }

        function mobileDevice() {
            let details = navigator.userAgent;
            let regexp = /android|iphone|ipad|kindle/i;
            let isMobile = regexp.test(details);

            if (isMobile) {
                return true;
            } else {
                return false;
            }
        }

        function topAnim() {
            const currentHeader = document.getElementById("hyperlinks");
            let movement = -window.pageYOffset/10;

            currentHeader.style.transform = "translateY(" + movement + "vw";

            if (window.pageYOffset > 2) {
                currentHeader.style.opacity = "" + 8/window.pageYOffset;
            } else {
                currentHeader.style.opacity = "" + 1;
            }
        }
        
        return (
            <body
                className="home-fullscreen"
                id="homey"
            >
                <div
                    className="home-header"
                >
                    <Header/>
                </div>
                <div
                    className="home align-who"
                >
                    <div
                        className="tag"
                    >
                        <div
                            className="who-am-i first"
                        >
                            <Tags
                                label="Who am I?"
                            />
                        </div>
                        <h4
                            className="text"
                        >
                            My name is Anderson Lawrence, and two years ago I attended Ithaca College for physics. 
                            I'm presently at Boston University, studying Computer Science and working towards a finance minor. 
                            When I'm at home in Belmont, Massachussets, I like to spend time with my friends, family, and border-collie Skye.
                        </h4>
                    </div>
                    <div
                        className="pic cali-me"
                    >
                        <FamilyImages
                            name={CaliMe}
                            caption="Me in California"
                        />
                    </div>
                </div>
                <div
                    className="home"
                >
                    <div
                        className="who-am-i second"
                    >
                        <Tags
                            label="What do I enjoy?"
                        />
                    </div>
                    <h4
                        className="text pad-right"
                    >
                        When I find myself with any free time, usually I enjoy listening to and creating music in
                        ProTools, finding a project, or playing the piano. I've also taken interest in learning programming
                        languages online, including Solidity and JavaScript.
                    </h4>
                </div>
                <div
                    className="home pics-bottom"
                >   
                    <div
                        className="picture piano-pic"
                    >
                        <FamilyImages
                            name={PianoPic}
                            caption="Piano Playing"
                        />
                    </div>
                    <div
                        className="picture family-pic"
                    >
                        <FamilyImages
                            name={FamilyPic}
                            caption="Me in California"
                        />
                    </div>
                    <div
                        className="picture skye-pic"
                    >
                        <FamilyImages
                            name={SkyeDog}
                            caption="Skye Dog"
                        />  
                    </div>
                </div>
           </body>
        );
    }
}

export default Homey;