import React from "react";
import "./Testing.css";
import fam from "../Pages/pics/FamChristmas.jpg";
import Entertainer from "../Pages/videos/Entertainer.mp4";
import Maple from "../Pages/videos/maple.mp4";
import Bruno from "../Pages/videos/brunomars.mp4";
import Weeping from "../Pages/videos/weeping-willow.mp4";
import Mysong from "../Pages/videos/mysong.mp4";
import Nocturne from "../Pages/videos/nocturne.mp4";
import Peacherine from "../Pages/videos/peacherine.mp4";
import scott from "../Pages/pics/scottjoplin.jpg"
import gladlibs from "../Pages/pics/Gladlibs.png";
import Header from "./Header.js";
import Tags from "../Pages/Tag";
import pic from '../Pages/pics/CaliMe.jpg';
import { useState } from "react";
import FamilyImages from "../Pages/FamilyImages";



const Newbutton = (props) => {
    const [pressed, setPressed] = useState(false);

    function startVid() {
        const vid = document.getElementById(props.videoName);

        const vids = document.getElementsByClassName("video-testing");
        const buttons = document.getElementsByClassName("button-testing");

        setPressed(!vid.paused);

        vid.onended = function() {
            for (var i = 0; i < vids.length; i++) {
                if (vids[i] == vid) {
                    buttons[i].textContent = "Play";
                }
            }
        }

        for (var i = 0; i < vids.length; i++) {
            if (!vids[i].paused && vids[i] != vid) {
                vids[i].pause();
                buttons[i].textContent = "Play";
            } else if (vids[i] == vid && vid.paused) {
                buttons[i].textContent = "Pause";
            } else if (vids[i] == vid && !vid.paused) {
                buttons[i].textContent = "Play";
            }
        }

        if (vid.paused) {
            vid.play();
            setPressed(!vid.paused);
        } else {
            vid.pause();
        }

    }

    function setFocussed() {
        let allDivs = document.getElementsByClassName("div-button-testing");
        let foc = document.getElementById(props.id);

        for(let i = 0; i < allDivs.length; i++) {
            if (allDivs[i] != foc && (allDivs[i].style.zIndex == "1")) {
                allDivs[i].style.zIndex = "0"
                allDivs[i].style.transform = "rotateY(25deg)";
            }
        }

        foc.style.zIndex = "1";
        const scale = "scaleX(1.25)";
        const rotation = "rotateY(-25deg)";

        if (props.id == "3rd" || props.id == "6th") {
            foc.style.transform = rotation + " translateX(-1vw) " + scale;
        } else if (props.id == "2nd" || props.id == "5th" || props.id == "7th") {
            foc.style.transform = rotation + " " + scale;
        } else {
            foc.style.transform = rotation + " translateX(1vw) " + scale;
        }
    }

    function setHovered() {
        let allDivs = document.getElementsByClassName("video-testing");
        let nonePlaying = true;

        for(let z = 0; z < allDivs.length; z++) {
            if(!allDivs[z].paused) {
                nonePlaying = false;
            }
        }

        if(nonePlaying) {
            setFocussed();
        }
    }

    return (
        <div
            className="div-button-testing"
            id={props.id}
            onClick={() => setFocussed()}
            onMouseOver={() => setHovered()}
        >
            <h5
                className="title-text" 
            >
                {props.title}
            </h5>
            <video
                id={props.videoName}
                width="100%"
                className="video-testing"
            >
                <source
                    src={props.video}
                    type="video/mp4"
                />
            </video>
            <button
                type="button"
                onClick={() => startVid()}
                className="button-testing"
            >
                {pressed ? "Pause" : "Play"}  
            </button>
        </div>
    );
}

class Testing extends React.Component {
    render() {
        return (
            <body
                id="piano"
            >
                <div
                    className="content"
                    title="Piano"
                >
                    <div
                        className="header-piano"
                        id="header-piano-div"
                    >
                        <Header/>
                    </div>
                    <div
                        className="piano-explanation-div"
                    >
                        <div
                            className="nrp"
                        >
                            <FamilyImages
                                name={scott}
                                caption="Scott Joplin"
                            />
                        </div>
                        <div
                            className="background"
                        >
                            <div
                                className="tag-background"
                            >
                                <Tags
                                    label="The Beginning"
                                />
                            </div>
                            <div>
                                <h4
                                    className="text text-background"
                                >
                                    It all happened years ago when I started taking lessons at Powers Music School. 
                                    Although I didn't entirely enjoy playing at the time, only a couple years after 
                                    I stopped taking lessons, I developed a passion for it. I began to teach myself 
                                    more challenging pieces, and with the freedom to choose my agenda, I started to 
                                    enjoy practicing about an hour or more each day. 
                                </h4>
                            </div>
                        
                        </div>
                    </div>
                    <div
                        className="align-music-choice"
                    >
                        <div
                            className="music-choice-tag"
                        >
                            <Tags
                                label="Music Choice"
                            />
                        </div>
                        <h4
                            className="text text-music-choice"
                        >
                            I really enjoy playing ragtime melodies. More specifically, I enjoy practicing Scott Joplin songs, 
                            as well as a mixture of classical and more modern peices. Below I've attached some videos of the 
                            songs that I've performed: please check them out!
                        </h4>
                    </div>
                        <div
                            className="videos-text-top"
                        >
                            <h4>
                                Videos
                            </h4>
                        </div>
                        <div
                            className="underline-videos-div"
                        >

                        </div>
                        <div
                            className="videos seperator-1 try-me-again"
                        >
                            <Newbutton
                                video={Entertainer}
                                videoName="video1"
                                title="Entertainer"
                                id="1st"
                            />
                            <Newbutton
                                video={Maple}
                                videoName="video2"
                                title="Maple Leaf"
                                id="2nd"
                            />
                            <Newbutton
                                video={Bruno}
                                videoName="video3"
                                title="Bruno Mars"
                                id="3rd"
                            />
                        </div>
                        <div>       
                            <i class="arrow down">
                            </i>
                        </div>
                        <div
                            className="videos seperator-2"
                            id="try-me-here"
                        >
                            <div
                                className="try-me-again"
                            >
                                <Newbutton
                                    video={Weeping}
                                    videoName="video4"
                                    title="Weeping Willow"
                                    className="position-1"
                                    id="4th"
                                />
                                <Newbutton
                                    video={Peacherine}
                                    videoName="video5"
                                    title="Peacherine Rag"
                                    className="position-1"
                                    id="5th"
                                />
                                <Newbutton
                                    video={Nocturne}
                                    videoName="video6"
                                    title="Noct. EF Major"
                                    className="position-1"
                                    id="6th"
                                />
                                </div>
                        </div>
                        <div>       
                            <i class="arrow down">
                            </i>
                        </div>
                        <div
                            className="videos seperator-3"
                        >   
                            <div
                                id="my-song"
                                className="try-me-again"
                            >
                                <Newbutton
                                    video={Mysong}
                                    videoName="video7"
                                    title="My Song"
                                    id="7th"
                                />
                            </div>
                        </div>
                </div>
            </body>
        );
    }
}

export default Testing;
